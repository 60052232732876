// router/index.js

import { createRouter, createWebHistory } from "vue-router";

import {
  publicPagesLeft,
  publicPagesRight,
  protectedRoutesAdministrative,
  protectedRoutesAttendanceChecker,
  protectedRoutesAttendanceGuest,
  protectedRoutesQuizCoordinator,
  protectedRoutesStudent,
  protectedRoutesUser,
  forgotPassword
} from "./routeConfig.js";

import CryptoJS from "crypto-js";

const secretKey = "iniSecretKeyAnda"; // Ganti dengan secret key yang aman

function decryptUser() {
  const encryptedUser = localStorage.getItem("OAT");
  if (encryptedUser) {
    const bytes = CryptoJS.AES.decrypt(encryptedUser, secretKey);
    const originalData = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(originalData);
  }
  return null;
}

function hasRole(userRoles, requiredRole) {
  return userRoles.includes(requiredRole);
}

const routes = [
  ...publicPagesLeft,
  ...publicPagesRight,
  ...protectedRoutesAdministrative,
  ...protectedRoutesAttendanceChecker,
  ...protectedRoutesAttendanceGuest,
  ...protectedRoutesQuizCoordinator,
  ...protectedRoutesStudent,
  ...protectedRoutesUser,

  // ... forgot password
  ...forgotPassword,

  {
    path: '/blog/:slug',
    component: () => import("../views/public/blogView.vue"),
  },

  {
    path: "/:catchAll(.*)",
    name: "not-found",
    component: () => import("../views/not-found/PageNotFound.vue"),
    meta: { requiresAuth: false }, // Public page, no authentication required
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const isPublicPageLeft = publicPagesLeft.includes(to.path);
  const isPublicPageRight = publicPagesRight.includes(to.path);

  if (isPublicPageLeft) {
    next();
  }

  if (isPublicPageRight) {
    next();
  }

  const requiresAuth = to.meta.requiresAuth;

  if (requiresAuth) {
    const loggedIn = localStorage.getItem("OAT");

    // Simpan URL asli sebelum redirect
    const returnUrl = to.path !== "/login" ? to.path : null;

    if (!loggedIn) {
      next({ path: "/login", query: { returnUrl } });
    } else {
      // Decrypt the role data from localStorage
      // Fungsi untuk mendekripsi data pengguna dari LocalStorage

      const user = decryptUser();

      // perbaiki lagi pada bagian ini
      const userRoles = user.roles; // Change this line
      const requiredRole = to.meta.role;

      if (!hasRole(userRoles, requiredRole)) {
        next({ path: "/unauthorized" });
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

export default router;
