// menuUtils.js

// Import the protectedRoutes array from routeConfig.js
import {
  protectedRoutesAdministrative,
  protectedRoutesAttendanceChecker,
  protectedRoutesAttendanceGuest,
  protectedRoutesStudent,
  protectedRoutesQuizCoordinator,
  publicPagesLeft,
  publicPagesRight,
} from "@/router/routeConfig.js";

// Function to generate the menu from protectedRoutes
export function itemMenuAdministrative() {
  const menu = [];

  for (const route of protectedRoutesAdministrative) {
    // Check if the route has a name and path
    if (route.icon && route.name && route.path) {
      // Create a menu item object with 'name' and 'path' properties
      const menuItem = {
        icon: route.icon,
        name: route.name,
        path: route.path,
      };

      // Push the menu item to the 'menu' array
      menu.push(menuItem);
    }
  }

  return menu;
}

// Function to generate the menu from protectedRoutes
export function itemMenuAttendanceChecker() {
  const menu = [];

  for (const route of protectedRoutesAttendanceChecker) {
    // Check if the route has a name and path
    if (route.icon && route.name && route.path) {
      // Create a menu item object with 'name' and 'path' properties
      const menuItem = {
        icon: route.icon,
        name: route.name,
        path: route.path,
      };

      // Push the menu item to the 'menu' array
      menu.push(menuItem);
    }
  }

  return menu;
}

// Function to generate the menu from protectedRoutes
export function itemMenuAttendanceGuest() {
  const menu = [];

  for (const route of protectedRoutesAttendanceGuest) {
    // Check if the route has a name and path
    if (route.icon && route.name && route.path) {
      // Create a menu item object with 'name' and 'path' properties
      const menuItem = {
        icon: route.icon,
        name: route.name,
        path: route.path,
      };

      // Push the menu item to the 'menu' array
      menu.push(menuItem);
    }
  }

  return menu;
}

export function itemMenuQuizCoordinator() {
  const menu = [];

  for (const route of protectedRoutesQuizCoordinator) {
    // Check if the route has a name and path
    if (route.icon && route.name && route.path) {
      // Create a menu item object with 'name' and 'path' properties
      const menuItem = {
        icon: route.icon,
        name: route.name,
        path: route.path,
      };

      // Push the menu item to the 'menu' array
      menu.push(menuItem);
    }
  }

  return menu;
}


// Function to generate the menu from protectedRoutes Student
export function itemMenuStudent() {
  const menu = [];

  for (const route of protectedRoutesStudent) {
    // Check if the route has a name and path
    if (route.icon && route.name && route.path) {
      // Create a menu item object with 'name' and 'path' properties
      const menuItem = {
        icon: route.icon,
        name: route.name,
        path: route.path,
      };

      // Push the menu item to the 'menu' array
      menu.push(menuItem);
    }
  }

  return menu;
}

export function itemMenuPublicLeft() {
  const menu = [];

  for (const route of publicPagesLeft) {
    // Check if the route has a name and path
    if (route.name && route.path) {
      // Create a menu item object with 'name' and 'path' properties
      const menuItem = {
        name: route.name,
        path: route.path,
      };

      // Push the menu item to the 'menu' array
      menu.push(menuItem);
    }
  }

  return menu;
}

export function itemMenuPublicRight() {
  const menu = [];

  for (const route of publicPagesRight) {
    // Check if the route has a name and path
    if (route.name && route.path) {
      // Create a menu item object with 'name' and 'path' properties
      const menuItem = {
        name: route.name,
        path: route.path,
      };

      // Push the menu item to the 'menu' array
      menu.push(menuItem);
    }
  }

  return menu;
}
