// router/routeConfig.js

const ADMINISTRATIVE_ROLE = "Administrative";
const ATTENDANCECHECKER_ROLE = "AttendanceChecker";
const ATTENDANCEGUEST_ROLE = "AttendanceGuest";
const QUIZCOORDINATOR_ROLE = "QuizCoordinator";
const STUDENT_ROLE = "Student";
const USER_ROLE = "User";

const protectedRoutesAdministrative = [
  {
    icon: "mdi-view-dashboard",
    name: "Dashboard *",
    path: "/administrative-dashboard",
    component: () => import("../views/administrative/dashboard.vue"),
    meta: { requiresAuth: true, role: ADMINISTRATIVE_ROLE },
  },
  {
    icon: "mdi-account",
    name: "Users *",
    path: "/administrative-user-management",
    component: () => import("../views/administrative/userManagement.vue"),
    meta: { requiresAuth: true, role: ADMINISTRATIVE_ROLE },
  },
  {
    icon: "mdi-calendar",
    name: "Academic Year *",
    path: "/administrative-academic-year",
    component: () => import("../views/administrative/academicYear.vue"),
    meta: { requiresAuth: true, role: ADMINISTRATIVE_ROLE },
  },
  {
    icon: "mdi-account-child-outline",
    name: "Student Group *",
    path: "/administrative-student-group",
    component: () => import("../views/administrative/studentGroup.vue"),
    meta: { requiresAuth: true, role: ADMINISTRATIVE_ROLE },
  },
  {
    icon: "mdi-account-group",
    name: "Student With Group *",
    path: "/administrative-student-group-student",
    component: () => import("../views/administrative/studentGroupStudent.vue"),
    meta: { requiresAuth: true, role: ADMINISTRATIVE_ROLE },
  },
  {
    icon: "mdi-shield-key-outline",
    name: "Token *",
    path: "/administrative-token",
    component: () => import("../views/administrative/token.vue"),
    meta: { requiresAuth: true, role: ADMINISTRATIVE_ROLE },
  },
  {
    icon: "mdi-account-details",
    name: "School Profile *",
    path: "/administrative-school-profile",
    component: () => import("../views/administrative/schoolProfile.vue"),
    meta: { requiresAuth: true, role: ADMINISTRATIVE_ROLE },
  },
  {
    icon: "mdi-image-multiple-outline",
    name: "Image Gallery *",
    path: "/administrative-image-gallery",
    component: () => import("../views/administrative/imageGallery.vue"),
    meta: { requiresAuth: true, role: ADMINISTRATIVE_ROLE },
  }, {
    icon: "mdi-pencil",
    name: "Blog *",
    path: "/administrative-blog",
    component: () => import("../views/administrative/blog.vue"),
    meta: { requiresAuth: true, role: ADMINISTRATIVE_ROLE },
  }, {
    icon: "mdi-history",
    name: "Login Log *",
    path: "/administrative-login-log",
    component: () => import("../views/administrative/loginLog.vue"),
    meta: { requiresAuth: true, role: ADMINISTRATIVE_ROLE },
  },
  {
    icon: "mdi-book",
    name: "Subject *",
    path: "/administrative-subject",
    component: () => import("../views/administrative/subject.vue"),
    meta: { requiresAuth: true, role: ADMINISTRATIVE_ROLE },
  }, {
    icon: "mdi-text-long",
    name: "Subject List *",
    path: "/administrative-subject-list",
    component: () => import("../views/administrative/subjectList.vue"),
    meta: { requiresAuth: true, role: ADMINISTRATIVE_ROLE },
  },
];

const protectedRoutesAttendanceChecker = [
  {
    icon: "mdi-view-dashboard",
    name: "Dashboard Checker",
    path: "/attendance-checker-dashboard",
    component: () => import("../views/attendance-checker/dashboard.vue"),
    meta: { requiresAuth: true, role: ATTENDANCECHECKER_ROLE },
  },
  {
    icon: "mdi-clock-check-outline",
    name: "Attendance Checker",
    path: "/attendance-checker-attendance",
    component: () => import("../views/attendance-checker/attendanceStudents.vue"),
    meta: { requiresAuth: true, role: ATTENDANCECHECKER_ROLE },
  }
];

const protectedRoutesAttendanceGuest = [
  {
    icon: "mdi-clock-check-outline",
    name: "Attendance Guest",
    path: "/attendance-guest-attendance",
    component: () => import("../views/attendance-guest/attendanceGuest.vue"),
    meta: { requiresAuth: true, role: ATTENDANCEGUEST_ROLE },
  },

];

const protectedRoutesQuizCoordinator = [
  {
    icon: "mdi-beaker-question-outline",
    name: "Quiz Maker",
    path: "/quiz-coordinator-quiz-maker",
    component: () => import("../views/quiz-coordinator/quizMaker.vue"),
    meta: { requiresAuth: true, role: QUIZCOORDINATOR_ROLE },
  },
  {
    icon: "mdi-progress-question",
    name: "Quiz Activity",
    path: "/quiz-coordinator-quiz-activity",
    component: () => import("../views/quiz-coordinator/quizActivity.vue"),
    meta: { requiresAuth: true, role: QUIZCOORDINATOR_ROLE },
  },
  {
    icon: "mdi-progress-check",
    name: "Quiz Result",
    path: "/quiz-coordinator-quiz-result",
    component: () => import("../views/quiz-coordinator/quizResult.vue"),
    meta: { requiresAuth: true, role: QUIZCOORDINATOR_ROLE },
  },
  {
    icon: "mdi-account-question-outline",
    name: "Quiz Group",
    path: "/quiz-coordinator-quiz-group",
    component: () => import("../views/quiz-coordinator/quizGroup.vue"),
    meta: { requiresAuth: true, role: QUIZCOORDINATOR_ROLE },
  }, {
    icon: "mdi-help-box-outline",
    name: "Quiz Card",
    path: "/quiz-coordinator-quiz-card",
    component: () => import("../views/quiz-coordinator/quizCard.vue"),
    meta: { requiresAuth: true, role: QUIZCOORDINATOR_ROLE },
  },
  {
    icon: "mdi-seat-outline",
    name: "Quiz Seat",
    path: "/quiz-coordinator-quiz-seat",
    component: () => import("../views/quiz-coordinator/quizSeat.vue"),
    meta: { requiresAuth: true, role: QUIZCOORDINATOR_ROLE },
  }, {
    icon: "mdi-draw-pen",
    name: "Quiz Attendances",
    path: "/quiz-coordinator-quiz-attendances",
    component: () => import("../views/quiz-coordinator/quizAttendance.vue"),
    meta: { requiresAuth: true, role: QUIZCOORDINATOR_ROLE },
  },
  {
    icon: "mdi-record-circle-outline",
    name: "Quiz Official Record",
    path: "/quiz-coordinator-quiz-official-record",
    component: () => import("../views/quiz-coordinator/quizOfficialRecord.vue"),
    meta: { requiresAuth: true, role: QUIZCOORDINATOR_ROLE },
  },
  {
    icon: "mdi-draw-pen",
    name: "Quiz Integrity Pact",
    path: "/quiz-coordinator-quiz-integrity-pact",
    component: () => import("../views/quiz-coordinator/quizIntegrityPact.vue"),
    meta: { requiresAuth: true, role: QUIZCOORDINATOR_ROLE },
  },
  
];


const protectedRoutesStudent = [
  {
    icon: "mdi-view-dashboard",
    name: "Student Dashboard",
    path: "/student-dashobard",
    component: () => import("../views/student/dashboard.vue"),
    meta: { requiresAuth: true, role: STUDENT_ROLE },
  }, {
    icon: "mdi-clock-check-outline",
    name: "Student Attendance",
    path: "/student-attendance",
    component: () => import("../views/student/attendance.vue"),
    meta: { requiresAuth: true, role: STUDENT_ROLE },
  },
  {
    icon: "mdi-help-box-multiple-outline",
    name: "Student Quiz List",
    path: "/student-quiz-list",
    component: () => import("../views/student/quizList.vue"),
    meta: { requiresAuth: true, role: STUDENT_ROLE },
  },
  {
    icon: "mdi-help-box-multiple",
    name: "Student Quiz Activity",
    path: "/student-quiz-available",
    component: () => import("../views/student/quizActivity.vue"),
    meta: { requiresAuth: true, role: STUDENT_ROLE },
  },

];

const protectedRoutesUser = [
  {
    icon: "mdi-account",
    name: "Profile",
    path: "/user-profile",
    component: () => import("../views/user/profile.vue"),
    meta: { requiresAuth: true, role: USER_ROLE },
  },
];

const publicPagesLeft = [
  {
    name: "Home",
    path: "/",
    component: () => import("../views/public/home.vue"),
  },
  {
    name: "About",
    path: "/about",
    component: () => import("../views/public/about.vue"),
  },
  {
    name: "Gallery",
    path: "/gallery",
    component: () => import("../views/public/gallery.vue"),
  },
  {
    name: "Dudika",
    path: "/dudika",
    component: () => import("../views/public/dudika.vue"),
  },
  {
    name: "PPDB",
    path: "/ppdb",
    component: () => import("../views/public/ppdb.vue"),
  },
  {
    name: "Blog",
    path: "/blogs",
    component: () => import("../views/public/blog.vue"),
  },


];

const publicPagesRight = [
  {
    name: "Login",
    path: "/login",
    component: () => import("../views/auth/Login.vue"),
  },
  {
    name: "Register",
    path: "/register",
    component: () => import("../views/auth/Register.vue"),
  },
];

const forgotPassword = [
  {
    name: "Forgot Password?",
    path: "/forgot-password",
    component: () => import("../views/auth/ForgotPassword.vue"),
  },
  {
    name: "Reset Password",
    path: "/reset-password/:token",
    component: () => import("../views/auth/ResetPassword.vue"),
  },
];

export {
  publicPagesLeft,
  publicPagesRight,
  protectedRoutesAdministrative,
  protectedRoutesAttendanceChecker,
  protectedRoutesAttendanceGuest,
  protectedRoutesQuizCoordinator,
  protectedRoutesStudent,
  protectedRoutesUser,
  forgotPassword,
};
