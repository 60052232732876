<template>
  <v-app id="inspire">
    <!-- ... Rest of the content ... -->

    <v-navigation-drawer v-if="currentUser" v-model="drawerCurrentUser">
      <!-- ... Rest of the content ... -->
      <v-list>

        <v-list-item v-if="currentUser.avatar" :prepend-avatar="currentUser.avatar" :title="currentUser.name"
          :subtitle="currentUser.email">
          <template v-slot:append>
            <v-btn size="small" variant="text" icon="mdi-cog" :to="'/user-profile'"></v-btn>
          </template>
        </v-list-item>

        <v-list-item v-else prepend-icon="mdi-account" :title="currentUser.name" :subtitle="currentUser.email">
          <template v-slot:append>
            <v-btn size="small" variant="text" icon="mdi-cog" :to="'/user-profile'"></v-btn>
          </template>
        </v-list-item>

      </v-list>
      <v-divider></v-divider>

      <v-list density="compact" nav>

        <span v-if="Administrative">
          <v-list-item v-for="(item, i) in menuAdministrative" :key="i" :to="item.path" path :prepend-icon="item.icon"
            :title="item.name">
          </v-list-item>
        </span>

        <span v-if="AttendanceChecker">
          <v-list-item v-for="(item, i) in menuAttendanceChecker" :key="i" :to="item.path" path :prepend-icon="item.icon"
            :title="item.name">
          </v-list-item>
        </span>

        <span v-if="Student">
          <v-list-item v-for="(item, i) in menuStudent" :key="i" :to="item.path" path :prepend-icon="item.icon"
            :title="item.name">
          </v-list-item>
        </span>

        <span v-if="AttendanceGuest">
          <v-list-item v-for="(item, i) in menuAttendanceGuest" :key="i" :to="item.path" path :prepend-icon="item.icon"
            :title="item.name">
          </v-list-item>
        </span>

        <span v-if="QuizCoordinator">
          <v-list-item v-for="(item, i) in menuQuizCoordinator" :key="i" :to="item.path" path :prepend-icon="item.icon"
            :title="item.name">
          </v-list-item>
        </span>


      </v-list>
      <!-- ... Rest of the content ... -->
    </v-navigation-drawer>

    <v-navigation-drawer v-model="drawerNoUser" temporary v-else>
      <!-- ... Rest of the content ... -->
      <v-list density="compact" nav>
        <v-list-item v-for="(item, i) in menuPublicLeft" :key="i" :to="item.path">{{ item.name }}</v-list-item>
        <v-list-item v-for="(item, i) in menuPublicRight" :key="i" :to="item.path">{{ item.name }}</v-list-item>
      </v-list>
      <!-- ... Rest of the content ... -->
    </v-navigation-drawer>


    <v-app-bar color="teal-darken-4" image="https://picsum.photos/1920/1080?random">
      <template v-slot:image>
        <v-img gradient="to top right, rgba(19,84,122,.8), rgba(128,208,199,.8)"></v-img>
      </template>


      <template v-slot:prepend>
        <v-app-bar-nav-icon v-if="currentUser" @click.stop="drawerCurrentUser = !drawerCurrentUser" />

        <span class="hidden-sm-and-up">
          <v-app-bar-nav-icon v-if="!currentUser" @click.stop="drawerNoUser = !drawerNoUser" />
        </span>
      </template>

      <v-app-bar-title>{{ appName }}</v-app-bar-title>

      <span class="d-none d-sm-flex" v-if="!currentUser">
        <v-btn variant="text" v-for="(item, i) in menuPublicLeft" :key="i" :to="item.path">
          {{ item.name }}
        </v-btn>
      </span>

      <v-spacer></v-spacer>

      <span class="d-none d-sm-flex" v-if="!currentUser">
        <v-btn variant="text" v-for="(item, i) in menuPublicRight" :key="i" :to="item.path">
          {{ item.name }}
        </v-btn>
      </span>

      <v-btn v-if="Administrative && $route.name !== 'Dashboard'" icon to="/administrative-dashboard">
        <v-icon>mdi-view-dashboard</v-icon>
      </v-btn>

      <v-btn icon @click="toggleTheme">
        <v-icon>mdi-theme-light-dark</v-icon>
      </v-btn>

      <v-btn variant="text" @click.prevent="logOut()" v-if="currentUser">
        <v-icon>mdi-power</v-icon>
      </v-btn>
    </v-app-bar>


    <v-main>
      <router-view />
    </v-main>

    <v-footer class="mt-0">
      <!-- ... Rest of the content ... -->
      <v-row>
        <v-col class="text-center" cols="12">
          <span>&copy; {{ new Date().getFullYear() }} {{ appName }}. All rights reserved.</span>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>

import EventBus from "./common/EventBus";
import { useTheme } from 'vuetify'
import api from "./services/api";

import { itemMenuAdministrative, itemMenuAttendanceChecker, itemMenuAttendanceGuest, itemMenuQuizCoordinator, itemMenuStudent, itemMenuPublicLeft, itemMenuPublicRight } from "@/utils/menuUtils.js";

export default {
  setup() {
    const theme = useTheme()
    return {
      theme,
      toggleTheme: () => theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark'
    }
  },

  data: () => ({
    drawerCurrentUser: false,
    drawerNoUser: false,

    appTitle: null,
    appName: null,
    appBio: null,

    menuPublicLeft: [],
    menuPublicRight: [],
    menuAdministrative: [],
    menuAttendanceChecker: [],
    menuAttendanceGuest: [],
    menuQuizCoordinator: [],
    menuStudent: [],

  }),

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },

    Administrative() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('Administrative');
      }
      return false;
    },

    AttendanceChecker() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('AttendanceChecker');
      }
      return false;
    },

    AttendanceGuest() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('AttendanceGuest');
      }
      return false;
    },

    QuizCoordinator() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('QuizCoordinator');
      }
      return false;
    },
  
    Student() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('Student');
      }
      return false;
    },
  },

  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },

    async index() {
      const response = await api.get('/public-index-logo');
      this.appName = response.data.name
    },


    async redirectIfLoggedIn() {

      if (this.Administrative) {
        this.$router.push("/administrative-dashboard");
      } else if (this.AttendanceChecker) {
        this.$router.push("/attendance-checker-dashboard");
      } else if (this.Student) {
        this.$router.push("/student-dashobard");
      }  

    },

  },

  mounted() {
    EventBus.on("logout", () => {
      this.logOut();
    });
  },

  created() {
    this.redirectIfLoggedIn()
    this.menuAdministrative = itemMenuAdministrative()
    this.menuAttendanceChecker = itemMenuAttendanceChecker()
    this.menuAttendanceGuest = itemMenuAttendanceGuest()
    this.menuQuizCoordinator = itemMenuQuizCoordinator()
    this.menuStudent = itemMenuStudent()
    this.menuPublicLeft = itemMenuPublicLeft()
    this.menuPublicRight = itemMenuPublicRight()
    this.index()
  },

  beforeUnmount() {
    EventBus.remove("logout");

  }
};
</script>

 