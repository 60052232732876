import api from "./api";
import TokenService from "./token.service";

class AuthService {
  login({ email, password, captcha }) {
    return api
      .post("/login", {        
        email,
        password,
        captcha
      })
      .then((response) => {
        if (response.data.accessToken) {
          TokenService.setUser(response.data);
        }

        return response.data;
      });
  }

  logout() {
    TokenService.removeUser();
  }

  register({ name, email, password, captcha }) {
    return api.post("/register", {
      name,
      email,
      password,
      captcha
    });
  }
}

export default new AuthService();
